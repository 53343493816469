.android-navigation .active {
    border-bottom: 4px solid #586896;
}

.mdl-layout__header-row .mdl-button {
    margin: 0 10px;
}

.mdl-button-login {
    order: 3;
    margin-left: 10px;
}

.mdl-button-login:active {
    position: relative;
    top: 1px;
}

.mdl-button-login:hover {
    background: #dbdbdb;
}

.mdl-button-login:active {
    box-shadow: 0 3px 7px #a8a8a8 inset, 1px 1px 1px 0 #a8a8a8;
}

.mdl-button-sign-up {
    order: 2;
}

.btn-theme {
    background: linear-gradient(#0eaea9, #006a65);
    color: #fff !important;
}

.btn-theme:active {
    position: relative;
    top: 1px;
}

.btn-theme:hover {
    background: #009d98 none repeat scroll 0 0;
}

.btn-theme:active {
    box-shadow: 0 3px 7px #006a65 inset, 1px 1px 1px 0 #006a65;
}

.button2Small {
    -moz-user-select: none;
    background: rgba(0, 0, 0, 0) linear-gradient(#ececec, #a8a8a8) repeat scroll 0 0;
    border: 0 none;
    border-radius: 3px;
    color: #000;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 23px;
    padding: 5px 20px;
    text-align: center;
    vertical-align: middle;
}

.button2Small:active {
    position: relative;
    top: 1px;
}

.button2Small:hover {
    background: #dbdbdb none repeat scroll 0 0;
}

.button2Small:active {
    box-shadow: 0 3px 7px #a8a8a8 inset, 1px 1px 1px 0 #a8a8a8;
}

.android-navigation .mdl-navigation__link {
    text-decoration: none;
    padding-top: 20px;
}

.loginForm * {
    text-align: center;
}

.login_modal_footer {
    margin-top: 5px;
}

.login_modal_header .modal-title {
    text-align: center;
    font-family: 'Philosopher', sans-serif;
}

.form-group {
    position: relative;
}

.form-group .login-field-icon {
    font-size: 20px;
    position: absolute;
    right: 15px;
    top: 3px;
    transition: all 0.25s ease 0s;
    padding-top: 2%;
}

.login-modal {
    width: 100%;
    padding-bottom: 0px;
}

.modal-register-btn {
    margin: 4% 33% 2% 33%;
    width: 100%;
}

.login-modal p {
    margin-bottom: 5px;
}

.login-modal input {
    box-shadow: none;
    border: 1px solid #ddd;
}

.modal-body-left {
    float: left;
    width: 50%;
    padding-right: 4%;
    border-right: 4px solid #ddd;
}

.modal-body-right {
    float: right;
    width: 47%;
}

.modal-social-icons.facebook,
.modal-social-icons .google {
    padding-top: 2%;
    margin-top: 2%;
}

.modal-icons {
    margin-left: -10px;
    margin-right: 20px;
}

.modal-social-icons .facebook,
.modal-social-icons .google {
    width: 100%;
    height: 40px;
    padding-top: 2%;
    margin-top: 2%;
}

.modal-social-icons .google, .modal-social-icons .google:hover {
    background-color: #dd4b39;
    border: 2px solid #dd4b39;
    color: #fff;
}

.modal-social-icons .facebook, .modal-social-icons .facebook:hover {
    background-color: #3b5999;
    border: 2px solid #3b5999;
    color: #fff;
}

#social-icons-conatainer {
    position: relative;
}

#center-line {
    position: absolute;
    right: 265.7px;
    top: 80px;
    background: #ddd;
    border: 4px solid #DDDDDD;
    border-radius: 20px;
}

.modal-login-btn {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
}

#modal-launcher {
    margin: 30% 0 0 30%;
}

.modal-social-icons h6 {
    text-align: center
}

.login_modal_header {
    padding: 5px 10px;
}

.login-modal p {
    font-size: 12px;
}

#login-overlay .modal-dialog {
    width: 400px;
    margin: 30px auto;
}

.lostPassWord {
    margin-top: 5px;
}

.bottomFooter {
    background-color: #263151;
    box-shadow: 0 5px 10px -5px #aaa inset;
    clear: both;
    float: left;
    width: 100%;
    margin-top: 50px;
}

#footer {
    bottom: 0;
    color: #000;
    width: 100%;
    z-index: 10;
}

.footer {
    margin-top: 45px;
}

.footer .links {
    clear: both;
    font-size: 14px;
}

.footer .links a {
    clear: both;
    color: #fff;
    float: left;
}

.footer .links a:hover {
    color: #aaa;
    text-decoration: none;
}

.footer .links > li {
    display: inline-block;
    letter-spacing: 0.03em;
    padding: 0 10px;
    text-transform: uppercase;
}

.footer h3 {
    color: #fff;
    margin-bottom: 20px;
    clear: both;
}

.footer .reachus {
    clear: both;
    color: #fff;
    float: left;
}

.footer .reachus .marker {
    color: #000;
}

.footer .reachus .paper-plane {
    color: #000;
}

.footer .reachus .envelope {
    color: #000;
}

.footer .social {
    font-size: 20px;
}

.facebook {
    color: #3b5998;
}

.facebook.fa:hover, .facebook .fa:hover {
    transform: scale(1.1);
}

.googleplus, .google {
    color: #da4835;
}

.googleplus:hover {
    transform: scale(1.1);
}

.pinterest {
    color: #bd081c;
}

.pinterest:hover {
    transform: scale(1.1);
}

.twitter {
    color: #24abe2;
}

.twitter:hover {
    transform: scale(1.1);
}

.footer .social .socialLink {
    font-size: 14px;
}

.footer .bar {
    font-size: 14px;
    padding: 0 10px;
}

.footer .fine-print {
    color: #b8c3c9;
    font-size: 12px;
}

@media screen and (max-width: 640px) {
    .links {
        display: block;
    }

    .links > li {
        display: block;
        letter-spacing: 0.06em;
        text-transform: uppercase;
    }

    .links > li > a {
        display: block;
        line-height: 40px;
    }

    .bar {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .footer {
        margin-top: 45px;
    }
}

@media screen and (max-width: 800px) {
    .footer {
        margin-top: 25px;
    }
}

.onepcssgrid-1000, .onepcssgrid-1100, .onepcssgrid-1200 {
    margin: 0 auto;
    padding: 0 0 0 1%;
}

.onepcssgrid-1200 {
    max-width: 1220px;
}

.onepcssgrid-1100 {
    max-width: 1090px;
}

.onepcssgrid-1000 {
    max-width: 1020px;
}

.onerow {
    clear: both;
    padding: 0 10px;
}

.get-started-btn {
    margin: 20px 0;
    max-width: 400px;
}

.single-quote {
    margin: 50px 0;
}

.section {
    margin: 20px 0;
}

.title {
    border-bottom: 2px solid;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.section .link {
    display: inline-block;
    font-size: 1.2em;
    margin: 5px 0;
}

.social-links {
    font-size: 30px;
}

.social-links a {
    margin: 5px;
    display: inline-block;
}

.registerError {
    color: #a94442;
}

.quote-card {
    width: 100%;
    margin: 20px 0;
}

.margin-right {
    margin: 10px;
}

.underline {
    text-decoration: underline;
}

.no-margin {
    margin: 0;
}

#authors-link {
    background: #085394;
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
    padding: 7px 0;
    margin-bottom: 20px;
}

#authors-link a.link {
    color: #fff;
    display: inline-block;
    padding: 5px
}

.change-view .active {
    color: #03345e;
}

.datepicker.datepicker-inline {
    width: 100%;
}

.datepicker-inline .day {
    line-height: 2;
}

.datepicker-inline .day {
    cursor: default !important;
}

.day > a {
    display: inline-block;
    padding: 0 5px;
    width: 100%;
    color: #fff;
    background-color: #286090;
}

.datepicker-inline .datepicker-days table tr td {
    padding: 0;
}

@media (max-width: 500px) {
    .pagination > li > a, .pagination > li > span {
        padding: 5px 7px;
        font-size: 12px;
        line-height: 1.5;
    }

    .pagination {
        margin-left: -5px;
        margin-right: -10px;
    }
}

.carousel .item.active{
    animation: showSweetAlert 0.5s;
}
.heartbeat {
    animation: showSweetAlert 1s 3;
}

a.text-love, .favorite-quote:hover{
    color: #EE8877;
}

.android-search-box {
    width: 50px;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;
}

.android-search-box.is-focused {
    width: 300px;
}

.android-search-box.is-focused ~ .userRegister,
.android-search-box.is-focused ~ .userLogin,
.android-search-box.is-focused ~ .mdl-button-login {
    opacity: 0;
}

.searchbox {
    position: relative;
    width: 100%;
}

.searchbox-input {
    top: 0;
    right: 0;
    border: 0;
    outline: 0;
    color: #000;
    width: 100%;
    height: 50px;
    margin: 0;
    opacity: 0;
}

.searchbox-open .searchbox-input {
    border-bottom: 1px solid #EDEDED;
    padding: 0px 55px 0px 20px;
    opacity: 1;
}

.searchbox-icon {
    width: 30px;
    height: 35px;
    display: block;
    position: absolute;
    top: 10px;
    font-size: 22px;
    right: 0;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    background: #EDEDED;
    color: #172b3c;
}

.android-header .dropdown-menu {
    left: -30px;
}